import React from 'react';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';
import ReactRotatingText from 'react-rotating-text';

const StyledPage = styled.body`
  width: 100%;
`

const StyledLeft = styled.body`
  width: 50%;
  position: fixed;
  background-color: white;
  color: black;
  cursor: w-resize;
`

const StyledRight = styled.body`
  width: 50%;
  margin-left: 50%;
  min-height: 100vh;
  position: fixed;
  background-color: black;
  color: white;
  cursor: e-resize;
`

const StyledText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14.5vw;
  text-align: center;
  margin-top: 25vh;
  margin-left: 39%;

  @media (max-width: 479px) {
    margin-top: 100%;
  }
`

const StyledAnimatedText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14.5vw;
  text-align: center;
  margin-top: 25vh;
  margin-right: 90%;

  .react-rotating-text-cursor {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
  }
  
  @keyframes blinking-cursor {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media (max-width: 479px) {
    margin-top: 100%;
  }
`

const Intro = () => {
  return(
    <StyledPage>
      <Helmet>
        <title>Navin</title>
      </Helmet>
      <NavLink to='/internal'>
        <StyledLeft>
          <StyledText>
            Nav
          </StyledText>
        </StyledLeft>
      </NavLink>
      <NavLink to='/external'>
        <StyledRight>
          <StyledAnimatedText>
            <ReactRotatingText items={['in.','Mani.', '🤑.', '.']} />
          </StyledAnimatedText>
        </StyledRight>
      </NavLink>
    </StyledPage>
  );
}

export default Intro;
