import React from 'react';
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  min-height: 100px;
  padding-left: 5%;
  padding-bottom: 2%;
  background-color: ${props => props.tao === 'yin' ? 'black' : 'white'};
`

const StyledLink = styled(NavLink)`
  font-size: 20px;
  font-family:'Open Sans', sans-serif;
  text-decoration: none;
  font-weight: 600;
  color: ${props => props.tao === 'yin' ? 'white' : 'black'};
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 479px) {
    font-size: 17px;
  }
`

const Navigation = (props) => {
  return (
    <StyledNav tao={props.tao}>
      <StyledLink to="/" tao={props.tao}>
        navin.money
      </StyledLink>
    </StyledNav>
  );
}

export default Navigation;