import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Intro from './pages/intro';
import Money from './pages/external';
import Navin from './pages/internal';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Intro} />
        <Route path="/internal" component={Navin} />
        <Route path="/external" component={Money} />
      </Switch>
    </Router>
  );
}

export default App;
