import React from 'react';
import styled from "styled-components";
import { Helmet } from 'react-helmet';
import Navigation from '../components/navigation';
import { NavLink } from "react-router-dom";

const StyledPage = styled.body`
  font-family: 'Open Sans', sans-serif;
`

const StyledLeft = styled.body`
  width: 10%;
  height: 100vh;
  position: fixed;
  background-color: white;
  color: black;
  cursor: w-resize;
`

const StyledRight = styled.body`
  width: 90%;
  margin-left: 10%;
  height: 100vh;
  position: fixed;
  background-color: black;
  color: white;
`

const StyledText = styled.p`
  font-size: 35px;
  margin-top: 9%;
  margin-left: 5%;
  margin-right: 15%;
  cursor: default;

  a {
    color: white;
  }

  @media (max-width: 479px) {
    font-size: 25px;
    line-height: 35px;
    margin-top: 15%;
    margin-left: 15%;
    margin-right: 15%;
  }
`

const Money = () => {
  return (
    <StyledPage>
      <NavLink to='/internal'>
        <StyledLeft />
      </NavLink>
      <StyledRight>
        <Navigation tao='yin'/>
        <Helmet>
          <title>External</title>
        </Helmet>
        <StyledText>
          <a href="mailto:navinrmani@gmail.com" target="_blank" rel="noopener noreferrer">Investing</a> in companies that democratize access to cheap capital and lay the groundwork for new economic infrastructure.
          <br/>
          Building <a href="https://aven.com/" target="_blank" rel="noopener noreferrer">Aven</a>.
        </StyledText>
      </StyledRight>
    </StyledPage>
  );
}

export default Money;